import Web3 from "web3";
import { ethers } from "ethers";
import store from "@/store";

const contract = require("../data/abi.json");

export default class Web3Service {
  constructor() {
    this.initProvider();
  }

  initProvider() {
    const provider = window?.ethereum;

    this.web3 = new Web3(provider);

    this.contractAddress = process.env.VUE_APP_CONTRACT_ADDRESS;

    this.contract = new this.web3.eth.Contract(contract, this.contractAddress);

    try {
      this.provider = new ethers.providers.Web3Provider(provider);
    } catch (error) {
      //
      console.log(error);
    }
    this.contractEthers = new ethers.Contract(
      this.contractAddress,
      contract,
      this.provider
    );
  }

  getWeb3() {
    return this.web3;
  }
  getContractEthers() {
    return this.contractEthers;
  }
  async swicthNetwork() {
    try {
      if (window.ethereum.networkVersion !== process.env.VUE_APP_CHAIN_ID) {
        try {
          await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [
              {
                chainId: this.web3.utils.toHex(process.env.VUE_APP_CHAIN_ID),
              },
            ],
          });
        } catch (err) {
          if (err.code === 4902) {
            await window.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainName: process.env.VUE_APP_CHAIN_NAME,
                  chainId: this.web3.utils.toHex(process.env.VUE_APP_CHAIN_ID),
                  nativeCurrency: {
                    name: "MATIC",
                    decimals: 18,
                    symbol: "MATIC",
                  },
                  rpcUrls: [process.env.VUE_APP_CHAIN_RPC_URL],
                },
              ],
            });
          } else {
            return { err: true, message: err.message };
          }
        }
        await this.initProvider();
      }
      return true;
    } catch (error) {
      return { err: true, message: error };
    }
  }

  async connectToContract() {
    return this.contract;
  }

  async getMintPrice() {
    const mintPrice = await this.contract.methods
      .getMintPrice()
      .call(function (err, res) {
        if (err)
          return {
            err: true,
            message: "Something went wrong, please try again.",
          };
        return res;
      });
    return mintPrice;
  }

  async requestRandom(address, time) {
    var _paq = (window._paq = window._paq || []);

    const mintPrice = await this.getMintPrice();

    if (mintPrice.err) return mintPrice;

    let res = null;

    try {
      await this.contract.methods
        .mintEtherneal(time)
        .call({ from: address, value: mintPrice });
    } catch (error) {
      try {
        let message = JSON.parse(
          error.message.substring(
            error.message.indexOf("{"),
            error.message.indexOf("}") + 1
          )
        ).message;

        message = message.replace("execution reverted:", "").trim();

        if (message.includes("-32000") && message.includes("-32603")) {
          message = "Increase gas.";

          window.fbq("trackCustom", "transactionUnderPrice");
          _paq.push([
            "trackEvent",
            "transactionUnderPrice",
            "transactionUnderPrice",
          ]);
        } else if (message.includes("insufficient funds")) {
          message = "Insufficient funds.";
          window.fbq("trackCustom", "insufficientFunds");
          _paq.push(["trackEvent", "insufficientFunds", "insufficientFunds"]);
        }

        return {
          err: true,
          message: message,
        };
      } catch {
        return {
          err: true,
          message: "Something went wrong, please try again.",
        };
      }
    }

    try {
      await this.contract.methods
        .mintEtherneal(time)
        .send(
          {
            from: address,
            value: mintPrice,
            maxFeePerGas: null,
            maxPriorityFeePerGas: null,
          },
          function (err, _res) {
            res = _res;
          }
        )
        .on("transactionHash", function (hash) {
          store.state.confirmation = hash;
        })
        .on("error", function (error) {
          let message = error.message;

          if (
            message &&
            message.includes("-32000") &&
            message.includes("-32603")
          ) {
            message = "Increase gas.";
            window.fbq("trackCustom", "transactionUnderPrice");
            _paq.push([
              "trackEvent",
              "transactionUnderPrice",
              "transactionUnderPrice",
            ]);
          }
          res = { err: true, message: message };
        });

      return res;
    } catch (error) {
      let message = error.message;

      if (message && message.includes("-32000") && message.includes("-32603")) {
        message = "Increase gas.";
      }

      return { err: true, message: message };
    }
  }

  async getTransactionReceipt(hash) {
    const response = await this.web3.eth
      .getTransactionReceipt(hash)
      .then((res) => res)
      .catch(() => {
        null;
      });

    return response;
  }

  async getCurrentMinting() {
    try {
      const currentChunk = await this.contract.methods.getCurrentChunk().call();
      const currentMinting = await this.contract.methods
        .getCurrentMinting()
        .call();
      const maxChunk = await this.contract.methods.getMaxChunk().call();

      return {
        minted: Number(currentChunk) + Number(currentMinting),
        maxChunk: Number(maxChunk),
      };
    } catch (error) {
      return { err: true };
    }
  }
}
