<template>
  <v-card class="steps-card">
    <h2 class="text-center white-text home-message--1">
      The time to mint your Etherneals NFT has come!
    </h2>

    <v-btn class="main-btn d-flex ma-auto">
      <span>Start</span>

      <v-dialog
        v-model="dialog"
        activator="parent"
        class="terms-dialog"
        persistent
        max-width="1000"
        height="100%"
      >
        <v-card class="pa-8 terms-card">
          <h2 class="text-center mb-5 text-white">Terms service</h2>

          <v-checkbox
            v-model="checkbox"
            class="terms-confirmation"
            color="#0c86ff"
            hide-details
          >
            <template #label>
              <h3 class="text-center text-white">
                Before minting your Etherneals NFT you need to agree to our
                <a
                  target="_blank"
                  href="https://nft.ethernity.cloud/terms-of-use.html"
                  @click.stop
                  >Terms of service</a
                >.
              </h3>
            </template>
          </v-checkbox>
        </v-card>
      </v-dialog>
    </v-btn>
  </v-card>
</template>

<script setup>
import { ref, watch, defineProps } from "vue";
import { useCookies } from "@vueuse/integrations/useCookies";

const props = defineProps({
  checkTerms: { type: Function, required: true },
});

const dialog = ref(false);
const checkbox = ref(false);

const cookies = useCookies();

watch(checkbox, (val) => {
  if (val) {
    cookies.set("terms", true, { maxAge: 600000 });

    setTimeout(() => {
      dialog.value = false;
    }, 200);

    /*   setTimeout(() => {
      const icon = document.getElementById("icon");
      icon.classList.toggle("unlock");
    }, 300); */

    setTimeout(() => {
      props.checkTerms(true);
    }, 1000);
  }
});
</script>

<style lang="scss">
.terms-dialog {
  .terms-card {
    background: transparent;
    border-radius: 8px !important;
    backdrop-filter: blur(100px);
    -webkit-backdrop-filter: blur(100px);
  }
  .terms-confirmation {
    .v-label {
      opacity: 1;
      white-space: break-spaces;
    }
    .v-selection-control {
      height: 100%;
      width: fit-content;
    }
    .v-selection-control__input {
      .v-icon {
        opacity: 1;
        color: #0c86ff;
      }
    }
  }
}

#icon {
  width: 15px;
  height: 19px;
  position: relative;
  cursor: pointer;
}

#icon rect,
#icon path {
  transition: all 0.5s ease-in-out;
}

#icon rect {
  fill: white;
}

#icon path {
  stroke: white;
  stroke-dasharray: 30;
  stroke-dashoffset: 5;
  fill: none;
}

#icon.unlock path {
  stroke-dasharray: 20;
}

/* keyhole */

#keyhole {
  width: 3px;
  height: 6px;
  border-radius: 25px;
  position: absolute;
  top: 60%;
  left: 60%;
  background-color: hsl(201, 27%, 10%);
  transform: translate(-50%, -50%) rotate(0deg);
  transition: all 0.5s ease-in-out;
  z-index: 1;
}

#icon.unlock #keyhole {
  transform: translate(-50%, -50%) rotate(-180deg);
}
</style>
