import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faSpinner,
  faCheck,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import "./assets/scss/index.scss";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";

import { useCookies } from "@vueuse/integrations/useCookies";
const cookies = useCookies();
if (cookies.get("terms")) {
  store.state.terms = true;
}

import mitt from "mitt";

const emitter = mitt();

library.add(faSpinner, faCheck, faCircleCheck, faChevronLeft, faChevronRight);

loadFonts();

const app = createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .component("font-awesome-icon", FontAwesomeIcon);
app.config.globalProperties.emitter = emitter;
app.mount("#app");
