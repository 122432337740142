import { createStore } from "vuex";

export default createStore({
  state: {
    walletAddress: null,
    network: null,
    tx: null,
    minted: false,
    newNft: null,
    confirmation: null,
    disconnect: false,
    terms: false,
    requestTime: null,
    requestId: null,
    queue: {},
  },
  getters: {
    getConfirmation(state) {
      return state.confirmation;
    },
  },
  mutations: {},
  actions: {},
  modules: {},
});
