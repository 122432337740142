import RepositoryFactory from "./repositories/RepositoryFactory";
const NFTsRepository = RepositoryFactory("nfts");

export async function getNftsFromEthAddress(address) {
  return await NFTsRepository.getNftsFromEthAddress(address)
    .then((res) => res.data)
    .catch((err) => {
      return { err: true, message: err };
    });
}
