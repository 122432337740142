<template>
  <div class="position-relative">
    <LoadingNft v-if="!isMobile()" ref="loadingNftAnimation"></LoadingNft>
    <LoadingNftMobile
      v-if="isMobile()"
      ref="loadingNftAnimationMobile"
    ></LoadingNftMobile>
    <div style="margin-top: 110px">
      <v-row>
        <v-col>
          <div class="text-center main-title">Etherneals NFT Collection</div>

          <div class="text-center main-subtitle">
            <hr />
            Charged by Ethernity CLOUD
            <hr />
          </div>

          <Transition name="slide-up" mode="out-in">
            <TermsService
              v-if="!$store.state.terms"
              :check-terms="checkTerms"
            ></TermsService>

            <ConnectStep
              ref="connectWallet"
              v-else-if="!$store.state.walletAddress"
            ></ConnectStep>

            <SwitchNetwork
              ref="switchNetwork"
              v-else-if="$store.state.walletAddress && !$store.state.network"
            ></SwitchNetwork>

            <MintStep
              ref="mintStep"
              v-else-if="$store.state.walletAddress && $store.state.network"
              @start-animation="startAnimation"
              @show-error="showError"
            ></MintStep>
          </Transition>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="price-message">
            {{
              mintPrice.fixedPrice
                ? `Price: ${mintPrice.maxPrice} MATIC`
                : `Price range: ${mintPrice.minPrice} MATIC - ${mintPrice.maxPrice} MATIC`
            }}
          </div>

          <div
            v-if="
              mintedNfts.maxChunk > 0 &&
              mintedNfts.maxChunk - mintedNfts.minted > 0
            "
            class="nft-minted-message"
          >
            Remaining NFTs during this round
            <span class="price-message-value">{{
              mintedNfts.maxChunk - mintedNfts.minted
            }}</span>
          </div>

          <div
            v-else-if="
              mintedNfts.maxChunk &&
              mintedNfts.maxChunk - mintedNfts.minted <= 0
            "
            class="nft-minted-message"
          >
            Current round has been succesfully minted.
          </div>
        </v-col>
      </v-row>

      <v-row class="home-message--2">
        <v-col>
          <div v-html="messageMinting"></div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, computed } from "vue";
import ConnectStep from "@/components/ConnectStep.vue";
import SwitchNetwork from "@/components/SwitchNetwork.vue";
import MintStep from "@/components/MintStep.vue";
import TermsService from "@/components/TermsService.vue";
import LoadingNft from "@/components/LoadingNft.vue";
import LoadingNftMobile from "@/components/LoadingNftMobile.vue";

import { useEmitter } from "@/plugins/useEmitter";
import { useStore } from "vuex";
import { EMPTY } from "@/assets/scripts/constants";

import platform from "platform";
import { getCurrentMinting } from "@/assets/scripts/web3";
import priceConfig from "../../priceConfig.json";

export default defineComponent({
  name: "HomeView",
  components: {
    ConnectStep,
    SwitchNetwork,
    MintStep,
    LoadingNft,
    TermsService,
    LoadingNftMobile,
  },
  methods: {
    isMobile() {
      return !!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },
  },
  setup() {
    const emitter = useEmitter();
    const store = useStore();

    const loadingNftAnimation = ref();
    const startedAnimation = ref(false);
    const loadingNftAnimationMobile = ref();
    const mintedNfts = ref({ minted: 0, maxChunk: 0 });

    const connectWallet = ref();
    const switchNetwork = ref();
    const mintStep = ref();

    const mintPrice = ref(priceConfig);

    const messageMinting = ref(
      `Explore the hidden corners of the galaxy and unleash the unseen powers. Step towards decentralization and confidentiality to touch the unattainable dream of humanity. Your journey starts here.`
    );

    const defaultMessage = ref(messageMinting.value);

    const isMobile = computed(
      () => platform.os.family === "iOS" || platform.os.family === "Android"
    );

    const txLink = computed(() => process.env.VUE_APP_TX_URL + store.state.tx);

    onMounted(() => {
      emitter.on("nft-minted", async (val) => {
        if (val && val != EMPTY) {
          const msg = `Congratulations! NFT <a href="https://polygonscan.com/token/${process.env.VUE_APP_CONTRACT_ADDRESS}?a=${val}" target="_blank">#${val}</a> has been successfully minted and is
          officially yours! You can verify the transaction details by clicking
          on this <a href="${txLink.value}" target="_blank">link</a>. `;
          messageMinting.value = msg;
        } else {
          messageMinting.value = defaultMessage.value;
        }
      });
      getNumberOfMintedNfts();
      setInterval(getNumberOfMintedNfts, 5000);
    });

    const getNumberOfMintedNfts = async () => {
      const res = await getCurrentMinting();
      if (res.err) return;
      mintedNfts.value = res;
    };

    const startAnimation = async () => {
      startedAnimation.value = true;
      if (!isMobile.value) loadingNftAnimation.value.startAnimation();
      else loadingNftAnimationMobile.value.startAnimationMobile();
    };

    const showError = (val) => {
      emitter.emit("set-error", val);
    };

    const checkTerms = (val) => {
      store.state.terms = val;
    };

    return {
      loadingNftAnimation,
      loadingNftAnimationMobile,
      startAnimation,
      startedAnimation,
      showError,
      connectWallet,
      switchNetwork,
      mintStep,
      messageMinting,
      checkTerms,
      mintedNfts,
      mintPrice,
    };
  },
});
</script>
<style lang="scss">
.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.5s ease-out;
}

.slide-up-enter-from {
  opacity: 0;
  transform: translateY(30px);
}

.slide-up-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}

.main-title {
  font-weight: 500;
  font-size: 40px;
}
.main-subtitle {
  font-weight: 300;
  font-size: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  hr {
    margin: 0px 5px;
    max-width: 50px;
    width: 100%;
    border: none;
    background: #ffffff;
    border-radius: 8px;
    height: 2px;
  }
  margin-bottom: 80px;
}
.home-message--1 {
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 44px;
}
.home-message--2 {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #ffffff;
  max-width: 600px;
  margin: auto;
  margin-top: 100px;
  margin-bottom: 20px;
  text-shadow: 1px 1px 2px rgba($color: #000000, $alpha: 0.4);
  font-weight: 500;
}
.nft-minted-message,
.price-message {
  text-align: center;
  font-size: 20px;
  //text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
  font-weight: bold;
  text-shadow: 2px 1px 5px;
  &-value {
    color: #f89430;
    font-size: 22px;
  }
}
@media screen and (max-width: 550px) {
  .home-message--1 {
    font-size: 24px;
  }
  .home-message--2 {
    font-size: 15px;
  }
  .main-title {
    font-size: 32px;
  }
  .main-subtitle {
    font-size: 24px;
  }
}
</style>
