<template>
  <v-card class="steps-card">
    <h2 class="text-center white-text home-message--1">
      Connect your wallet to mint your Etherneals NFT!
    </h2>

    <div class="d-flex justify-center">
      <v-btn
        class="main-btn d-flex"
        style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
        :disabled="loading"
        @click="connect"
      >
        <div v-if="loading">Connecting...</div>
        <div v-else-if="!address">Connect</div>
        <div v-else>Connected</div>
      </v-btn>
    </div>
    <div v-if="metamaskError" class="metamask-error">
      Install Metamask&nbsp;
      <a href="https://metamask.io/download" target="_blank"> here</a>
    </div>
  </v-card>
</template>
<script>
import { defineComponent, ref, onMounted } from "vue";
import { useStore } from "vuex";
import { connectedAccount, connectWallet } from "../assets/scripts/web3";
export default defineComponent({
  setup() {
    const store = useStore();

    const address = ref();
    const loading = ref(false);
    const metamaskError = ref(false);

    onMounted(async () => {
      if (!store.state.disconnect) checkConnected();

      window.ethereum.on("accountsChanged", () => {
        checkConnected();
      });
    });

    const checkConnected = async () => {
      if (!store.state.terms) return;

      const accounts = await connectedAccount();

      if (!accounts.err && accounts[0]) {
        address.value = accounts[0];

        setTimeout(() => {
          store.state.walletAddress = accounts[0];
        }, 500);
      }
    };

    const connect = async () => {
      var _paq = (window._paq = window._paq || []);
      window.fbq("trackCustom", "nftConnectBtn");
      _paq.push(["trackEvent", "nftConnectBtn", "nftConnectBtn"]);

      if (!window.ethereum) {
        window.fbq("trackCustom", "noMetamaskWallet");
        _paq.push(["trackEvent", "noMetamaskWallet", "noMetamaskWallet"]);
        metamaskError.value = true;
        return;
      }
      if (loading.value) return;

      loading.value = true;

      const accounts = await connectWallet();

      if (!accounts.err) {
        address.value = accounts[0];

        setTimeout(() => {
          store.state.walletAddress = accounts[0];
        }, 500);
      }
      loading.value = false;
    };

    return {
      connect,
      loading,
      address,
      metamaskError,
      store,
    };
  },
});
</script>

<style lang="scss">
.metamask-error {
  position: absolute;
  color: #cf6679;
  font-size: 13px;
  top: auto;
  font-size: 13px;
  display: flex;
  justify-content: center;
  left: 0;
  right: 0;
}
.login-btn {
  background: linear-gradient(#f89430, #e97400) !important;
}
.loading-icon {
  font-size: 35px !important;
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
