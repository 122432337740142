<template>
  <v-app>
    <StartingAnimation></StartingAnimation>
    <v-slide-x-transition>
      <v-alert
        v-if="error.show"
        class="alert-error"
        type="error"
        border="start"
        >{{ error.message }}</v-alert
      >
    </v-slide-x-transition>
    <v-app-bar
      style="
        box-shadow: none;
        background: no-repeat;
        position: relative;
        flex: none;
        padding: 0px 24px;
      "
    >
      <div class="main-logo" @click="track">
        <v-img
          width="50px"
          max-width="50px"
          src="./assets/images/logo.svg"
        ></v-img>

        <div class="main-logo-title">Etherneals NFT</div>
      </div>

      <v-spacer></v-spacer>

      <div
        v-if="$store.state.walletAddress"
        class="d-flex align-center justify-between mobile-actions"
      >
        <button
          class="disconnect-btn d-flex"
          variant="text"
          @click="disconnect"
        >
          <div>Disconnect</div>
        </button>

        <MyNfts></MyNfts>
      </div>
    </v-app-bar>
    <v-main class="custom-main mx-auto px-4">
      <router-view />
    </v-main>
    <footer class="footer">
      <div class="footer-title">
        © 2023. Ethernity CLOUD Ltd. All rights reserved.
      </div>
      <div class="footer-socials">
        <a
          @click="
            () => {
              trackSocial('discord');
            }
          "
          href="https://discord.com/invite/fN4fn4PBcj"
          target="_blank"
        >
          <v-img width="22" src="./assets/images/socials/discord.svg"></v-img>
        </a>
        <a
          @click="
            () => {
              trackSocial('telegram');
            }
          "
          href="https://t.me/ethernitycloud"
          target="_blank"
        >
          <v-img width="22" src="./assets/images/socials/telegram.svg"></v-img>
        </a>
        <a
          @click="
            () => {
              trackSocial('fb');
            }
          "
          href="https://www.facebook.com/Ethernity.cloud"
          target="_blank"
        >
          <v-img width="22" src="./assets/images/socials/fb.svg"></v-img>
        </a>
        <a
          @click="
            () => {
              trackSocial('instagram');
            }
          "
          href="https://www.instagram.com/ethernity.cloud"
          target="_blank"
        >
          <v-img width="22" src="./assets/images/socials/instagram.svg"></v-img>
        </a>
        <a
          @click="
            () => {
              trackSocial('twitter');
            }
          "
          href="https://twitter.com/EthernealsNFT"
          target="_blank"
        >
          <v-img width="22" src="./assets/images/socials/twitter.svg"></v-img>
        </a>
        <a href="https://ethernity.cloud/" target="_blank">
          <v-img
            width="22"
            src="./assets/images/socials/ethernity-mini.svg"
          ></v-img>
        </a>
      </div>
    </footer>
  </v-app>
</template>

<script>
import { defineComponent, ref, onMounted } from "vue";
import { useStore } from "vuex";

import MyNfts from "./components/MyNfts.vue";
import StartingAnimation from "@/components/StartingAnimation.vue";

import { useEmitter } from "./plugins/useEmitter";
export default defineComponent({
  name: "App",

  components: { MyNfts, StartingAnimation },

  setup() {
    const store = useStore();
    const emitter = useEmitter();

    const error = ref({ show: false, message: null });

    onMounted(() => {
      emitter.on("set-error", (val) => {
        error.value = val;
        setTimeout(() => {
          error.value = { show: false, message: null };
        }, 5000);
      });

      if (window.ethereum) {
        window?.ethereum.on("chainChanged", function () {
          store.state.network = false;
        });
      }
    });

    const track = () => {
      window.open("https://nft.ethernity.cloud/");
      var _paq = (window._paq = window._paq || []);
      _paq.push(["trackEvent", "nftMyEtherneals", "nftMyEtherneals"]);
      window.fbq("trackCustom", "nftMyEtherneals");
    };

    const trackSocial = (social) => {
      var _paq = (window._paq = window._paq || []);
      _paq.push(["trackEvent", "nftSocialsLink", social]);
      window.fbq("trackCustom", "nftSocialsLink");
    };

    const disconnect = () => {
      store.state.disconnect = true;
      store.state.walletAddress = null;
      store.state.tx = null;
      store.state.minted = false;
      store.state.confirmation = null;
      store.state.newNft = null;
      store.state.requestTime = null;
      store.state.requestId = null;
      store.state.queue = {};
    };
    return { error, disconnect, track, trackSocial };
  },
});
</script>

<style lang="scss">
.custom-main {
  padding: 0px 20px;
  width: 100%;
}
.disconnect-btn {
  background: transparent;
  color: white;
  padding: 7px 13px;
  text-align: center;
  vertical-align: middle;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5;
  z-index: 999;
  margin-right: 25px;
}
.disconnect-btn:hover {
  background: transparent !important;
  color: #f89430 !important;
}
.footer {
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: transparent !important;
  margin: auto;
  margin-bottom: 0px;
  width: 100%;
  &-title {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-socials {
    display: flex;
    align-items: center;
    justify-content: center;
    a {
      margin: 12px;
    }
    a:hover {
      filter: invert(39%) sepia(61%) saturate(3204%) hue-rotate(194deg)
        brightness(100%) contrast(102%);
    }
  }
}

.main-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  margin: auto;
  right: 0;
  left: 0;
  &-title {
    margin-left: 15px;
    font-weight: 600;
    color: white;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 20px;
  }
}

.alert-error {
  width: fit-content;
  max-width: 500px;
  position: fixed;
  top: 10px;
  left: 5px;
  z-index: 9999;
}
@media screen and (max-width: 991px) {
  .main-logo-title {
    font-size: 14px;
  }
}
@media screen and (max-width: 650px) {
  .v-toolbar {
    overflow: initial !important;
  }
  .main-logo {
    right: auto;
  }
  .mobile-actions {
    flex-direction: column-reverse;
    margin-top: 50px;
    .disconnect-btn {
      margin-right: 0;
    }
  }
}
</style>
