<template>
  <v-dialog v-model="dialog" class="nfts-dialog">
    <template v-slot:activator="{ props }">
      <button class="btn-ethernals" v-bind="props">My Etherneals</button>
    </template>
    <v-card
      class="pa-8 ethernals-card"
      width="100%"
      max-width="800"
      height="600"
    >
      <div
        style="
          position: absolute;
          right: 20px;
          top: 20px;
          cursor: pointer;
          z-index: 9;
        "
      >
        <v-icon color="white" @click="dialog = false">mdi-close</v-icon>
      </div>
      <h1 class="text-center text-white">My Etherneals NFTs</h1>

      <h2
        v-if="mints.length == 0 && !loading"
        class="text-center mb-5 text-white"
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        "
      >
        Waiting for your first Etherneals NFT to be minted
      </h2>
      <div v-if="mints.length > 0" class="nft-carousel">
        <div
          class="nft-carousel-slide"
          :class="{ 'nft-carousel-slide--inactive': currentSlide !== i }"
          v-for="(item, i) in mints"
          :key="i"
        >
          <div class="nft-carousel-slide-item" v-if="currentSlide === i">
            <v-img
              width="400px"
              min-height="400px"
              max-height="450px"
              :src="parseImgUrl(item.media[0].raw)"
              class="ma-auto"
            >
              <template v-slot:placeholder>
                <div class="d-flex align-center justify-center fill-height">
                  <v-progress-circular
                    color="#f89430"
                    indeterminate
                  ></v-progress-circular>
                </div>
              </template>
            </v-img>
            <h1 class="text-center">{{ item.metadata.name }}</h1>
          </div>
        </div>
        <div class="carousel-next" @click="changeSlide(1)">
          <font-awesome-icon icon="fa-solid fa-chevron-right" />
        </div>
        <div class="carousel-prev" @click="changeSlide(-1)">
          <font-awesome-icon icon="fa-solid fa-chevron-left" />
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { getNftsFromEthAddress } from "@/assets/scripts/ApiFunctions";
import { processCidUrl } from "@/assets/scripts/common";
import { defineComponent, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  setup() {
    const store = useStore();

    const mints = ref([]);
    const loading = ref(false);
    const dialog = ref(false);
    const currentSlide = ref(0);

    watch(
      () => store.state.newNft,
      (val) => {
        if (val) initNfts();
      }
    );

    onMounted(() => {
      initNfts();
    });

    const initNfts = async () => {
      if (!store.state.walletAddress) return;
      loading.value = true;
      const res = await getNftsFromEthAddress(store.state.walletAddress);
      if (!res.err) {
        mints.value = res.ownedNfts;
        mints.value.sort((a, b) => a.id.tokenId - b.id.tokenId);
      }
      loading.value = false;
    };

    const changeSlide = (i) => {
      if (i < 0 && currentSlide.value > 0) currentSlide.value += i;
      if (i > 0 && currentSlide.value < mints.value.length - 1)
        currentSlide.value += i;
    };

    const parseImgUrl = (url) => {
      return processCidUrl(url);
    };

    return {
      mints,
      loading,
      dialog,
      currentSlide,
      changeSlide,
      parseImgUrl,
    };
  },
  data: () => ({
    settings: {
      itemsToShow: 1,
      snapAlign: "center",
    },
    breakpoints: {
      700: {
        itemsToShow: 3.5,
        snapAlign: "center",
      },
      1024: {
        itemsToShow: 5,
        snapAlign: "start",
      },
    },
  }),
});
</script>

<style lang="scss">
.btn-ethernals {
  background: transparent;
  background-color: transparent;
  color: white;
  border: solid;
  border-radius: 6px;
  border-width: 1px;
  padding: 10px 16px;
  border-color: #e97401;
  text-align: center;
  vertical-align: middle;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5;
  z-index: 999;
}
.nfts-dialog {
  .v-overlay__content {
    max-width: 800px;
    width: 100%;
    padding: 12px;
  }
}
.btn-ethernals:hover {
  background: #e9750110;
  border-color: #0c86ff;
  border-width: 1px;
}
.ethernals-card {
  background: transparent;
  border-radius: 8px !important;
  backdrop-filter: blur(100px);
  -webkit-backdrop-filter: blur(100px);
}
.nft-card {
  background: rgba(0, 0, 0, 0.5) !important;
  color: white;
  border-radius: 6px;

  &-img {
    border-radius: 6px;
  }
  &-attributes {
    ul {
      text-align: center;
      list-style: none;
    }
  }
}
.nft-carousel {
  display: flex;
  position: relative;
  color: white;

  &-slide {
    display: block;
    padding: 0 40px;
    width: 100%;
    &-item {
      color: white;
      text-align: center;
      img {
        max-width: 400px;
        width: 100%;
      }
    }
  }
  &-slide--inactive {
    display: none;
  }
  .carousel-next,
  .carousel-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    border-radius: 100%;
    color: white;
    background: #f89430;
    cursor: pointer;
  }
  .carousel-next {
    right: 0;
  }
  .carousel-prev {
    left: 0;
  }
}

.carousel__item {
  min-height: 200px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
