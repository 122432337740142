<template>
  <v-card class="steps-card">
    <h2 class="text-center white-text home-message--1">
      Switch to Polygon network to mint your Etherneals NFT!
    </h2>
    <div class="d-flex align-center justify-center">
      <v-btn
        class="main-btn"
        :disabled="loading || !$store.state.walletAddress"
        @click="swicthNetwork(true)"
      >
        <div v-if="loading">Switching...</div>
        <div v-else-if="!network || !$store.state.walletAddress">Switch</div>
        <div v-else>Connected</div>
      </v-btn>
    </div>
  </v-card>
</template>
<script>
import { swicthNetworkToPolygon } from "@/assets/scripts/web3";
import { defineComponent, ref, onMounted } from "vue";
import { useStore } from "vuex";
export default defineComponent({
  setup() {
    const store = useStore();
    const network = ref(false);
    const loading = ref(false);

    onMounted(() => {
      checkNetwork();
    });

    const checkNetwork = () => {
      if (window.ethereum.networkVersion == process.env.VUE_APP_CHAIN_ID) {
        swicthNetwork(false);
      }
    };

    const swicthNetwork = async (user) => {
      if (loading.value) return;

      loading.value = true;

      if (user) {
        var _paq = (window._paq = window._paq || []);
        window.fbq("trackCustom", "switchNetworkBtn");
        _paq.push(["trackEvent", "switchNetworkBtn", "switchNetworkBtn"]);
      }

      const res = await swicthNetworkToPolygon();

      if (!res.err) {
        network.value = true;

        setTimeout(() => {
          store.state.network = true;
        }, 500);
      }
      loading.value = false;
    };

    const disconnect = () => {
      store.state.walletAddress = null;
    };

    return { swicthNetwork, loading, network, disconnect, checkNetwork };
  },
  components: {},
});
</script>
<style lang="scss">
.login-btn {
  background: linear-gradient(#f89430, #e97400) !important;
}
.loading-icon {
  font-size: 35px !important;
}
</style>
