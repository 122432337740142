<template>
  <v-card class="steps-card">
    <h2 class="text-center white-text home-message--1">
      The time to mint your Etherneals NFT has come!
    </h2>

    <div class="d-flex align-center justify-center">
      <div style="position: relative"></div>
    </div>

    <div class="d-flex align-center justify-center">
      <div>
        <v-btn
          v-if="!$store.state.tx"
          class="main-btn"
          @click="mintNft"
          :disabled="
            loading || !$store.state.walletAddress || !$store.state.network
          "
        >
          <div v-if="loading">Minting...</div>
          <div v-else>Mint</div>
        </v-btn>

        <v-btn v-else class="main-btn d-flex mx-auto" @click="mintAgain">
          <div>Mint</div>
        </v-btn>
      </div>
    </div>
  </v-card>
</template>
<script>
import { EMPTY } from "@/assets/scripts/constants";
import axios from "axios";
import { defineComponent, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import {
  connectToContract,
  getContractEthers,
  mint,
} from "../assets/scripts/web3";
import axiosRetry from "axios-retry";
import { useEmitter } from "@/plugins/useEmitter";
import { processCidUrl } from "@/assets/scripts/common";

axiosRetry(axios, {
  retries: 10,
  retryDelay: (retryCount) => {
    return retryCount * 1000;
  },
});

export default defineComponent({
  components: {},
  setup(props, context) {
    const store = useStore();

    const emitter = useEmitter();

    const error = ref();
    const tx = ref();
    const loading = ref(false);

    watch(
      () => store.state.minted,
      (current) => {
        if (current) {
          loading.value = false;

          if (store.state.newNft && store.state.newNft != EMPTY) {
            const name = store.state.newNft.name;

            emitter.emit("nft-minted", name.split("#")[1].trim());
          } else {
            emitter.emit("nft-minted", EMPTY);
          }
        }
      }
    );

    watch(
      () => store.getters.getConfirmation,
      (val) => {
        if (val) context.emit("start-animation");
      }
    );

    onMounted(async () => {
      const contract = getContractEthers();

      store.state.queue = {};
      contract.on("MintedNft", (address, tokenURI, requestId) => {
        /*   console.log(
          "MintedNft",
          address,
          tokenURI,
          requestId.toString(),
          store.state.requestId,
          store.state.requestTime
        ); */
        store.state.queue[requestId.toString()] = {
          address,
          tokenURI,
        };
        getDataToken(address, tokenURI, requestId.toString());
      });

      contract.on("MintRequested", (address, requestId, time) => {
        /*     console.log(
          "MintRequested",
          address,
          requestId.toString(),
          time.toString()
        ); */
        if (
          store.state.walletAddress == address &&
          store.state.requestTime == time.toString()
        ) {
          store.state.requestId = requestId.toString();
          if (store.state.queue[requestId.toString()] !== undefined) {
            getDataToken(
              store.state.queue[requestId.toString()].address,
              store.state.queue[requestId.toString()].tokenURI,
              requestId.toString()
            );
          }
        }
      });
    });

    const getDataToken = async (address, tokenURI, requestId) => {
      if (
        store.state.walletAddress !== address ||
        store.state.requestId !== requestId
      )
        return;
      let done = false;

      done = await fetchToken(tokenURI);

      if (!done) {
        store.state.newNft = EMPTY;
        store.state.minted = true;
      }
    };

    const fetchToken = async (tokenURI) => {
      return await axios
        .get(processCidUrl(tokenURI))
        .then((res) => {
          const nft = res.data;

          store.state.newNft = nft;

          store.state.minted = true;

          return true;
        })
        .catch((err) => {
          console.log(err);
          return false;
        });
    };

    const mintNft = async () => {
      if (loading.value) return;

      var _paq = (window._paq = window._paq || []);
      window.fbq("trackCustom", "nftMint");
      _paq.push(["trackEvent", "nftMint", "nftMint"]);

      loading.value = true;

      await connectToContract();

      const requestTime = new Date().getTime();
      store.state.requestTime = requestTime;

      const responseMinting = await mint(
        store.state.walletAddress,
        requestTime
      );

      if (responseMinting.err) {
        error.value = responseMinting.message;

        context.emit("show-error", {
          show: true,
          message: responseMinting.message,
        });

        try {
          window.fbq("trackCustom", "mintError", responseMinting.message);
          _paq.push([
            "trackEvent",
            "mintError",
            "mintError",
            responseMinting.message,
          ]);
        } catch (err) {
          //
        }
      } else {
        tx.value = responseMinting;
        store.state.tx = responseMinting;
      }
      loading.value = false;
    };

    const mintAgain = () => {
      var _paq = (window._paq = window._paq || []);
      _paq.push(["trackEvent", "nftLfgAgain", "nftLfgAgain"]);
      window.fbq("trackCustom", "nftLfgAgain");

      emitter.emit("nft-minted", null);
      loading.value = false;
      store.state.tx = null;
      store.state.minted = false;
      store.state.confirmation = null;
      store.state.newNft = null;
      store.state.requestTime = null;
      store.state.requestId = null;
      store.state.queue = {};
      mintNft();
    };

    return {
      mintNft,
      error,
      tx,
      loading,
      mintAgain,
    };
  },
});
</script>
<style lang="scss">
.login-btn {
  background: linear-gradient(#f89430, #e97400) !important;
}

.loading-icon {
  font-size: 35px !important;
}

.minted-message {
  position: absolute;
  top: 35px;
  color: #4caf4f;
  font-size: 13px;
}
</style>
