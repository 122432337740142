import Web3Service from "./Web3Service";

const web3 = new Web3Service();

export async function connectWallet() {
  try {
    return await web3.getWeb3().eth.requestAccounts();
  } catch (error) {
    return { err: true, message: error.message };
  }
}

export async function connectedAccount() {
  try {
    return await web3.getWeb3().eth.getAccounts();
  } catch (error) {
    return { err: true, message: error.message };
  }
}
export async function swicthNetworkToPolygon() {
  return await web3.swicthNetwork();
}
export async function connectToContract() {
  return await web3.connectToContract();
}
export async function mint(address, time) {
  return await web3.requestRandom(address, time);
}
export function getContractEthers() {
  return web3.getContractEthers();
}
export function swicthNetwork() {
  return web3.swicthNetwork();
}

export function getTransactionReceipt(hash) {
  return web3.getTransactionReceipt(hash);
}
export async function getCurrentMinting() {
  return await web3.getCurrentMinting();
}
