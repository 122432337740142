<template>
  <v-dialog v-model="startedAnimation" fullscreen :scrim="false">
    <v-card class="loading-card" id="mint-nft-animation">
      <div v-if="!store.state.newNft && store.state.newNft != EMPTY">
        <div class="animation-text">{{ messages[currentMessage] }}</div>
      </div>

      <div id="main-container" class="circles-container">
        <!-- OUTTER CIRCLE -->
        <img
          id="sigil-1"
          class="circle"
          :src="require('../assets/images/animation-images/1.png')"
        />
        <img
          id="sigil-2"
          class="circle"
          :src="require('../assets/images/animation-images/2.png')"
        />
        <img
          id="sigil-3"
          class="circle"
          :src="require('../assets/images/animation-images/3.png')"
        />
        <img
          id="sigil-4"
          class="circle"
          :src="require('../assets/images/animation-images/4.png')"
        />
        <img
          id="sigil-5"
          class="circle"
          :src="require('../assets/images/animation-images/5.png')"
        />
        <img
          id="sigil-6"
          class="circle"
          :src="require('../assets/images/animation-images/6.png')"
        />
        <img
          id="sigil-7"
          class="circle"
          :src="require('../assets/images/animation-images/7.png')"
        />
        <img
          id="sigil-8"
          class="circle"
          :src="require('../assets/images/animation-images/8.png')"
        />
        <img
          id="sigil-9"
          class="circle"
          :src="require('../assets/images/animation-images/9.png')"
        />
        <img
          id="sigil-10"
          class="circle"
          :src="require('../assets/images/animation-images/10.png')"
        />

        <!-- INNER CIRCLE -->
        <div class="inner-circle-container"></div>

        <!-- <div id="nft-name" class="nft-name"></div>-->

        <img id="nft-image" />

        <div class="message" id="message-congrulation"></div>

        <button id="again-mint-btn" class="main-btn close-animation-btn">
          <div class="d-flex align-center justify-center">
            <div>LFG Again</div>
            <img :src="require('../assets/images/rocket.png')" />
          </div>
        </button>

        <div id="message"></div>
      </div>
      <!--   <div v-html="template"></div> -->
    </v-card>
  </v-dialog>
</template>

<script setup>
import platform from "platform";
import { EMPTY } from "@/assets/scripts/constants";
import { defineExpose, watch, ref, computed } from "vue";
import { useStore } from "vuex";
import { getTransactionReceipt } from "@/assets/scripts/web3";
import { processCidUrl } from "@/assets/scripts/common";

const store = useStore();

const CIRCLE_RADIUS = 320;
const ELEMENTS_COUNT = 10;

let elements;
let elements_container;
let inner_elements_container;
let nft_image;
let message_congrulation;
let message;

let startCode = null;

const startedAnimation = ref(false);
const currentMessage = ref(0);

let timeOut = null;

const messages = [
  "Human curiosity is much stronger than any force.",
  "Each god answers a clear question of human nature.",
  "They are coming ... and with them all the rewards.",
  "Thrust appeared to guide his successors.",
  "Maion, the serpent, comes with the power of metamorphosis.",
  "Eldir, the third god, supports harmony in the ecosystem.",
  "Logos enforces fairness for the actors in the ecosystem.",
  "When there is knowledge there is also abundance and expansion.",
  "Oros, the fifth god, mastered all of the universe's wealth.",
  "The goddess, Mindor, ensures equilibrium with her power.",
  "The circle closes with Thezius, a disciple of action.",
  "He will act quickly when he feels his presence is required.",
  "Now that we have all the resources, what comes next?",
];

const isMobile = computed(
  () => platform.os.family === "iOS" || platform.os.family === "Android"
);

watch(
  () => store.state.newNft,
  (val) => {
    if (val) {
      if (!isMobile.value) nftHasArrived();
    }
  }
);

const txLink = computed(() => process.env.VUE_APP_TX_URL + store.state.tx);

async function startAnimation() {
  setInterval(() => {
    if (currentMessage.value === messages.length - 1) currentMessage.value = 0;
    else currentMessage.value++;
  }, 5000);

  startedAnimation.value = true;

  await sleep(50);

  document.getElementById("mint-nft-animation").style.overflow = "hidden";
  if (!startCode)
    startCode = document.getElementById("main-container").innerHTML;
  else document.getElementById("main-container").innerHTML = startCode;

  document.getElementById("nft-image").style.opacity = "0";
  document.getElementById("nft-image").style.height = "0px";

  document.getElementById("again-mint-btn").addEventListener("click", () => {
    startedAnimation.value = false;
  });

  document
    .getElementById("again-mint-btn")
    .classList.remove("close-animation-btn_active");

  elements = document.getElementsByClassName("circle");
  elements_container = document.getElementsByClassName("circles-container")[0];
  inner_elements_container = document.getElementsByClassName(
    "inner-circle-container"
  )[0];

  nft_image = document.getElementById("nft-image");

  message_congrulation = document.getElementById("message-congrulation");
  message = document.getElementById("message");

  elements_container.classList.add("circles-container-animation");

  for (let i = 0; i < ELEMENTS_COUNT; i++) {
    const element = elements[i];
    const angle = (i / ELEMENTS_COUNT) * 2 * Math.PI;
    const x = CIRCLE_RADIUS * Math.cos(angle);
    const y = CIRCLE_RADIUS * Math.sin(angle);
    element.style.transform = `translate(${x}px, ${y}px)`;
  }
  await sleep(3000);
  stopAnimation();
}
async function stopAnimation() {
  elements_container.style.animation = "fast-rotate 2s linear infinite";
  inner_elements_container.style.boxShadow =
    "rgb(12 130 255) 0px 0px 60px 20px, rgb(233 160 255) 0px 0px 100px 50px, rgb(255 255 255) 0px 0px 94px 80px";

  for (let i = 0; i < ELEMENTS_COUNT; i++) {
    const element = elements[i];
    element.style.transition = `transform 2s ease`;
    element.style.transform = `translate(0)`;
    element.style.width = "170px";
    element.style.filter = `invert(36%) sepia(45%) saturate(2086%) hue-rotate(194deg) brightness(112%) contrast(106%) blur(10px)`;
  }

  timeOut = setTimeout(() => {
    if (!store.state.minted) {
      store.state.newNft = EMPTY;
      store.state.minted = true;
    }
  }, 180000);
}
async function nftHasArrived() {
  var _paq = (window._paq = window._paq || []);
  window.fbq("trackCustom", "nftFinishedMinting");
  _paq.push(["trackEvent", "nftFinishedMinting", "nftFinishedMinting"]);

  elements_container.style.animation = "rotate 2s linear infinite";
  inner_elements_container.style.boxShadow =
    "rgb(12 130 255) 0px 0px 60px 30px, rgb(233 160 255) 0px 0px 100px 70px, rgb(255 255 255) 0px 0px 94px 100px";
  await sleep(500);

  document.getElementById("mint-nft-animation").style.overflow = "auto";

  inner_elements_container.style.boxShadow = "0 0 200px 300px #fff";
  inner_elements_container.style.zIndex = "-1";

  await sleep(300);
  elements_container.style.animation = "none";
  nft_image.style.boxShadow = "0 0 100px 100px #fff";
  inner_elements_container.style.animation = "none";

  if (store.state.newNft && store.state.newNft != EMPTY) {
    nft_image.src = processCidUrl(store.state.newNft.image);

    message_congrulation.innerText = `Congratulations, ${store.state.newNft.name} is yours!`;
  } else {
    const txCheck = await getTransactionReceipt(store.state.confirmation);

    if (txCheck && txCheck.status) {
      message.innerHTML = `Your minting transaction was executed successfully. Your Etherneals NFT it's on its way. You can check the original transaction <a href="${txLink.value}" target="_blank">here</a>`;
    } else if (txCheck && txCheck.status == false) {
      message.innerHTML = `Unfortunately, your transaction has been reverted. You can check the original transaction <a href="${txLink.value}" target="_blank">here</a>`;
    } else {
      message.innerHTML = `Looks like your transaction is still in a pending state. Please review your wallet.`;
    }
  }

  clearTimeout(timeOut);

  document
    .getElementById("again-mint-btn")
    .classList.add("close-animation-btn_active");

  nft_image.style.display = "block";
  nft_image.style.opacity = "1";
  nft_image.style.height = "400px";
}

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

defineExpose({
  startAnimation,
});
</script>

<style lang="scss" scoped>
.loading-card {
  background-image: url("../assets/images/background.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.animation-text {
  display: block;
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  margin: 0;
  //font-family: "Montserrat", sans-serif;
  font-size: 28px;
  font-weight: 700;
  color: white;
  text-align: center;
  opacity: 0;
  animation: animation-text 5s infinite;
  text-shadow: 0px 20px 40px rgba(0, 0, 0, 0.25);
  font-family: "Inter", sans-serif !important;
}

.nft-name {
  display: block;
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  margin: 0;
  //font-family: "Montserrat", sans-serif;
  font-size: 28px;
  font-weight: 700;
  color: white;
  text-align: center;
  text-shadow: 1px 1px 2px black;
  font-family: "Inter", sans-serif !important;
}
.circles-container {
  height: 700px;
  width: 700px;
  margin: auto;
  position: relative;
}

.inner-circle-container {
  top: 50%;
  left: 50%;
  position: absolute;
  animation: counter-rotate 5s linear infinite;
  transition: all 2s ease;
}

.circles-container-animation {
  animation: rotate 12s linear infinite;
}

#nft-image {
  height: 0px;
  opacity: 0;
  transition: all 1s ease;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: shake 2s linear infinite;
}

#message,
.message {
  position: absolute;
  top: 45%;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  // display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  font-weight: 700;
  color: white; //rgb(12 130 255);
  text-shadow: 1px 1px 2px black;
  transition: all 1s ease;
  font-family: "Inter", sans-serif !important;
}
#message-congrulation {
  top: auto !important;
  bottom: 80px !important;
  font-size: 25px !important;
  text-shadow: 1px 1px 2px black !important;
  text-align: center;
}

.circle,
.inner-circle {
  position: absolute;
  top: calc(50% - 75px);
  left: calc(50% - 75px);
  width: 150px;
  transform: translate(0);
  transition: all 1s ease;
  filter: invert(36%) sepia(45%) saturate(2086%) hue-rotate(194deg)
    brightness(112%) contrast(106%);
}

.close-animation-btn {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  margin: auto !important;
  width: fit-content !important;
  display: none !important;
  color: white;
  img {
    width: 25px !important;
  }
  z-index: 9999999999;
}

.close-animation-btn_active {
  display: block !important;
}

/* AICI MODIFICI DIN 3deg si -3deg in unghi mai mic sau mai mare */
@keyframes shake {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  25% {
    transform: translate(-50%, -50%) rotate(3deg);
  }
  50% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  75% {
    transform: translate(-50%, -50%) rotate(-3deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fast-rotate {
  0% {
    transform: rotate(0deg);
  }
  70% {
    transform: rotate(300deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes counter-rotate {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
@keyframes animation-text {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
