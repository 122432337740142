<template>
  <v-dialog v-model="startedAnimation" fullscreen>
    <v-card class="loading-card">
      <div v-if="!store.state.newNft && store.state.newNft != EMPTY">
        <div class="animation-text">{{ messages[currentMessage] }}</div>
      </div>
      <div id="mint-nft-animation_mobile">
        <div class="lds-default">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>

        <img id="nft-image" />

        <div class="message" id="message-congrulation"></div>

        <button id="again-mint-btn" class="main-btn close-animation-btn">
          <div class="d-flex align-center justify-center">
            <div>LFG Again</div>
            <img :src="require('../assets/images/rocket.png')" />
          </div>
        </button>

        <div id="message"></div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script setup>
import platform from "platform";
import { EMPTY } from "@/assets/scripts/constants";
import { defineExpose, watch, ref, computed } from "vue";
import { useStore } from "vuex";
import { getTransactionReceipt } from "@/assets/scripts/web3";
import { processCidUrl } from "@/assets/scripts/common";

const store = useStore();

let nft_image;
let message_congrulation;
let message;

const startedAnimation = ref(false);
const currentMessage = ref(0);

let startCode = null;

let timeOut = null;
const txLink = computed(() => process.env.VUE_APP_TX_URL + store.state.tx);

const messages = [
  "Human curiosity is much stronger than any force.",
  "Each god answers a clear question of human nature.",
  "They are coming ... and with them all the rewards.",
  "Thrust appeared to guide his successors.",
  "Maion, the serpent, comes with the power of metamorphosis.",
  "Eldir, the third god, supports harmony in the ecosystem.",
  "Logos enforces fairness for the actors in the ecosystem.",
  "When there is knowledge there is also abundance and expansion.",
  "Oros, the fifth god, mastered all of the universe's wealth.",
  "The goddess, Mindor, ensures equilibrium with her power.",
  "The circle closes with Thezius, a disciple of action.",
  "He will act quickly when he feels his presence is required.",
  "Now that we have all the resources, what comes next?",
];

const isMobile = computed(
  () => platform.os.family === "iOS" || platform.os.family === "Android"
);
watch(
  () => store.state.newNft,
  (val) => {
    if (val) {
      if (isMobile.value) nftHasArrived();
    }
  }
);
async function startAnimationMobile() {
  setInterval(() => {
    if (currentMessage.value === messages.length - 1) currentMessage.value = 0;
    else currentMessage.value++;
  }, 5000);

  startedAnimation.value = true;

  await sleep(50);

  if (!startCode)
    startCode = document.getElementById("mint-nft-animation_mobile").innerHTML;
  else
    document.getElementById("mint-nft-animation_mobile").innerHTML = startCode;

  message_congrulation = document.getElementById("message-congrulation");
  message = document.getElementById("message");
  nft_image = document.getElementById("nft-image");
  document.getElementById("again-mint-btn").addEventListener("click", () => {
    startedAnimation.value = false;
  });
  document
    .getElementById("again-mint-btn")
    .classList.remove("close-animation-btn_active");

  nft_image.src = "";
  nft_image.style.opacity = "0";
  nft_image.style.height = "0";
  nft_image.style.boxShadow = "none";

  await sleep(3000);

  stopAnimation();
}
async function stopAnimation() {
  timeOut = setTimeout(() => {
    if (!store.state.minted) {
      store.state.newNft = EMPTY;
      store.state.minted = true;
    }
  }, 180000);
}
async function nftHasArrived() {
  var _paq = (window._paq = window._paq || []);
  window.fbq("trackCustom", "nftFinishedMinting");
  _paq.push(["trackEvent", "nftFinishedMinting", "nftFinishedMinting"]);

  await sleep(500);

  nft_image.style.boxShadow = "0 0 100px 100px #fff";

  if (store.state.newNft && store.state.newNft != EMPTY) {
    nft_image.src = processCidUrl(store.state.newNft.image);

    message_congrulation.innerText = `Congratulations, ${store.state.newNft.name} is yours!`;
  } else {
    const txCheck = await getTransactionReceipt(store.state.confirmation);

    if (txCheck && txCheck.status) {
      message.innerHTML = `Your minting transaction was executed successfully. Your Etherneals NFT it's on its way. You can check the original transaction <a href="${txLink.value}" target="_blank">here</a>`;
    } else if (txCheck && txCheck.status == false) {
      message.innerHTML = `Unfortunately, your transaction has been reverted. You can check the original transaction <a href="${txLink.value}" target="_blank">here</a>`;
    } else {
      message.innerHTML = `Looks like your transaction is still in a pending state. Please review your wallet.`;
    }
  }

  clearTimeout(timeOut);

  document
    .getElementById("again-mint-btn")
    .classList.add("close-animation-btn_active");

  nft_image.style.display = "block";
  nft_image.style.opacity = "1";
  nft_image.style.height = "300px";
}

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

defineExpose({
  startAnimationMobile,
});
</script>

<style lang="scss" scoped>
.loading-card {
  background-image: url("../assets/images/background.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.animation-text {
  display: block;
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  font-size: 18px;
  font-weight: 700;
  margin: 0 20px;
  color: white;
  text-align: center;
  opacity: 0;
  animation: animation-text 5s infinite;
  text-shadow: 0px 20px 40px rgba(0, 0, 0, 0.25);
  font-family: "Inter", sans-serif !important;
}

.nft-name {
  display: block;
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  margin: 0;
  font-size: 28px;
  font-weight: 700;
  color: white;
  text-align: center;
  text-shadow: 1px 1px 2px black;
  font-family: "Inter", sans-serif !important;
}

#nft-image {
  height: auto !important;
  width: 70% !important;
  opacity: 0;
  transition: all 1s ease;
  position: absolute;
  top: calc(50% - 20px);
  left: 50%;
  transform: translate(-50%, -50%);
  animation: shake 2s linear infinite;
}

#message,
.message {
  position: absolute;
  top: 45%;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  //display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  font-weight: 700;
  color: white; //rgb(12 130 255);
  text-shadow: 1px 1px 2px black;
  transition: all 1s ease;
  font-family: "Inter", sans-serif !important;
}
#message-congrulation {
  top: auto !important;
  bottom: 90px !important;
  font-size: 18px !important;
  text-shadow: 1px 1px 2px black !important;
  text-align: center;
  margin: 0 20px;
}

.close-animation-btn {
  position: absolute;
  bottom: 25px;
  left: 0;
  right: 0;
  margin: auto !important;
  width: fit-content !important;
  display: none !important;
  color: white;
  img {
    width: 25px !important;
  }
  z-index: 9999999999;
}

.close-animation-btn_active {
  display: block !important;
}

.lds-default {
  display: inline-block;
  position: absolute;
  width: 80px;
  height: 80px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
.lds-default div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: linear-gradient(135deg, #f89430 13px, #e97400 calc(100% - 13px));
  border-radius: 50%;
  animation: lds-default 1.2s linear infinite;
}
.lds-default div:nth-child(1) {
  animation-delay: 0s;
  top: 37px;
  left: 66px;
}
.lds-default div:nth-child(2) {
  animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}
.lds-default div:nth-child(3) {
  animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}
.lds-default div:nth-child(4) {
  animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}
.lds-default div:nth-child(5) {
  animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}
.lds-default div:nth-child(6) {
  animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}
.lds-default div:nth-child(7) {
  animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}
.lds-default div:nth-child(8) {
  animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}
.lds-default div:nth-child(9) {
  animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}
.lds-default div:nth-child(10) {
  animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}
.lds-default div:nth-child(11) {
  animation-delay: -1s;
  top: 62px;
  left: 52px;
}
.lds-default div:nth-child(12) {
  animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}
@keyframes lds-default {
  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.8);
    background: linear-gradient(
      135deg,
      #0c86ff 13px,
      #0000b7 calc(100% - 13px)
    );
  }
}
</style>
