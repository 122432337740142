<template>
  <v-card v-if="startedAnimation" class="starting-loading-card">
    <div class="lds-default">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </v-card>
</template>

<script setup>
import { ref } from "vue";

const startedAnimation = ref(true);

startAnimation();

async function startAnimation() {
  startedAnimation.value = true;

  await sleep(3000);
  startedAnimation.value = false;
}

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
</script>

<style lang="scss">
.starting-loading-card {
  background-image: url("../assets/images/background.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9999999;
  overflow: hidden;
}

.lds-default {
  display: inline-block;
  position: absolute;
  width: 80px;
  height: 80px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
.lds-default div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: linear-gradient(135deg, #f89430 13px, #e97400 calc(100% - 13px));
  border-radius: 50%;
  animation: lds-default 1.2s linear infinite;
}
.lds-default div:nth-child(1) {
  animation-delay: 0s;
  top: 37px;
  left: 66px;
}
.lds-default div:nth-child(2) {
  animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}
.lds-default div:nth-child(3) {
  animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}
.lds-default div:nth-child(4) {
  animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}
.lds-default div:nth-child(5) {
  animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}
.lds-default div:nth-child(6) {
  animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}
.lds-default div:nth-child(7) {
  animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}
.lds-default div:nth-child(8) {
  animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}
.lds-default div:nth-child(9) {
  animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}
.lds-default div:nth-child(10) {
  animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}
.lds-default div:nth-child(11) {
  animation-delay: -1s;
  top: 62px;
  left: 52px;
}
.lds-default div:nth-child(12) {
  animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}
@keyframes lds-default {
  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.8);
    background: linear-gradient(
      135deg,
      #0c86ff 13px,
      #0000b7 calc(100% - 13px)
    );
  }
}
</style>
